// Notification bar containing a link to re-verification page
import { breakpoint } from "../../utils/breakpoint/breakpoint";
import { Link } from "@reach/router";
import React from "react";
import styled from "styled-components";
import { useLocation } from "@reach/router";

/*
The #85b70a 'after' header line starts at 7-2=5(px) down from div itself. This is
coming from these values: "height: 2px; margin-top: 7px;" Also need 1px gap to separate
this notification bar and the header line. Hence, margin-top is 6px here.

Removing left, top & right borders for tablet & higher resolutions, because they only
look good in vertical layout (text above button) due to it containing a lot of empty space 
on the sides (the top one needed to complete the square as 3 borders look awkward).
*/
const StyledNotificationOuter = styled.div`
  background-color: #f1cc75;
  margin-top: 6px;
  padding: 10px 0;
  border: 4px solid rgba(0, 0, 0, 0.05);
  ${breakpoint("md")`
    border: none;
    border-bottom: 4px solid rgba(0, 0, 0, 0.05);
  `}
`;

const StyledNotificationInner = styled.div`
  max-width: 980px;
  margin: 0 auto;
  padding: 0 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  ${breakpoint("md")`
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  `};
  ${breakpoint("lg")`
    padding-right: 5px;
  `}
  & > * {
  }
`;

const StyledInfoText = styled.p`
  white-space: break-spaces;
  color: #202005;
  margin: 0;
  ${breakpoint("sm")`
    white-space: normal;
  `};
  ${breakpoint("md")`
    margin-right: 10px;
  `}
`;

const StyledLink = styled(Link)`
  background-color: #fafafa;
  white-space: nowrap;
  border-radius: 5px;
  border-bottom: 3px solid;
  text-decoration: none;
  margin: 1.2rem 20% 0px 20%;
  text-align: center;
  padding: 0.5rem 0;
  font-size: 1.1rem;
  ${breakpoint("sm")`
    margin: 1.2rem 25% 0px 25%;
    font-size: 1.2rem;
    padding: 10px;
  `};
  ${breakpoint("md")`
      margin: 0;
      font-size: 1rem;
      padding: 8px 10px;
  `};
  &:hover {
    background: #f0f0ff;
  };
  &:active {
    border-top: 2px solid rgba(0,0,0,0.2);
    border-bottom: 1px solid;
  };
`;

// the newlines are needed for mobile portrait resolutions
const ReverifyNotificationBar = () => {
  const location = useLocation();
  const destinationPath = "/reverification-checklist";

  if (location.pathname === destinationPath) return <></>;

  const informationText = "Information about your organisations and/or its listings may be out of date. "
    + "\n\nPlease click the button to see the organisations and listings that require re-verification."

  return (
    <>
      <StyledNotificationOuter>
        <StyledNotificationInner>    
          <StyledInfoText>{informationText}</StyledInfoText>
          <StyledLink to={destinationPath}>Click here</StyledLink>
        </StyledNotificationInner>
      </StyledNotificationOuter>
    </>
  );
}

export default ReverifyNotificationBar;
