import AppLoading from "../../../AppLoading";
import { breakpoint } from "../../../utils/breakpoint/breakpoint";
import React, { useState, useEffect, useContext } from "react";
import ReverificationForm from "../ReverificationForm/ReverificationForm";
import ServiceService from "../../../services/ServiceService/ServiceService";
import styled from "styled-components";
import { toast } from "react-toastify";
import UserContext from "../../../context/UserContext/UserContext";

function buildFormServiceItem(organisationService, storedServices) {
  const wasChecked = storedServices
    ?.find(ss => ss.id === organisationService.id) // find the stored service matching your fetched & iterated organisationService
    ?.checked === true; // was the stored service checked? (explicit 'true' check as it may be possible for checked to be 'undefined')

  return {
    id: organisationService.id,
    name: `service_${organisationService.id}`, // Name of field returned from 'getValues' object, as well as HTML name. - Not used!
    label: organisationService.name, // Text next to the checkbox.
    checked: wasChecked
  }
}

// Expects an organisation that is 'inRevalidationProcess': true
function buildFormObject(organisation, allExistingServices) {
  // get all services associated with an organisation.
  const organisationServices = allExistingServices.filter(s => s.organisation_id === organisation.id);
  const organisationHtmlName = `organisation_${organisation.id}`;
  // when nothing in storage, 'getItem' returns 'null' & json parse parses that 'null' into 'null'.
  const storedFormState = JSON.parse(sessionStorage.getItem(organisationHtmlName));
  const formServiceObjs = organisationServices.map(os => buildFormServiceItem(os, storedFormState?.services));

  return {
    organisation: {
      id: organisation.id,
      name: organisation.name,
      markupName: organisationHtmlName,
      checked: storedFormState?.organisation.checked === true
    },
    services: formServiceObjs
  }
};

async function dataLoader(userContext, formsSetter, loadingFlagSetter) {
  /* Creating a copy & set its organisation to be an array the ideal feature implementation would involve
    an API endpoint that returns organisations collection under a user instead of the 'firstOrDefault' 
    organisation like it does now. As such, not to taint the front end with bad API implementation
    consequences, I'm creating a copy variable that acts as if API has returned a proper response. This
    way the front-end logic can be written in a fairly general manner, which will require less tweaks
    when the API response is updated. */
  const user = {...userContext};
  /* In order to avoid breaking the existing V1 contract and needing to write V2,
    it's best to expect a new key to be added rather than the existing one to be
    modified. */
  user.organisations = [user.organisation];
  // if user has no organisations, then they don't have any services 
  if (!user.organisation) return;
  // get all existing services, because there's isn't an endpoint to the organisation ones
  const allServices = await ServiceService.retrieveServices({ limit: 9999, });
  // when it fails, it returns 'false' rather than proper data
  if (allServices) {
    // take all organisations under a user, filter them down to ones that are 'inRevalidationProcess': true
    const revalidationFormsData = user.organisations
      .filter(o => o.inRevalidationProcess) // If there're no orgs in db with this flag true, then just invert the condition: !o.inRevalidationProcess
      .map(o => buildFormObject(o, allServices)); // Go over every organisation, retrieve its services & build a form object

    formsSetter(revalidationFormsData);
  } else {
    toast.error(
      "Encountered an error while retrieving the services.",
      {
        autoClose: 10000,
        pauseOnHover: true,
      });
  }

  loadingFlagSetter(false);
}

/* It's quick because it doesn't involve any network requests & doesn't involve reprocessing the information
  we already have processed. User Context updates by itself on navigation. */
const quickUIUpdate = (organisationId, [formsData, formsDataSetter], [context, setContext]) => {
  const onlyInRevalidationForms = formsData.filter(f => f.organisation.id !== organisationId);
  formsDataSetter(onlyInRevalidationForms);
  // TODO: change this to work with 'organisations' instead of 'organisation', when the API is updated. 
  setContext({ 
    ...context, 
    organisation: { ...context.organisation, inRevalidationProcess: false } 
  });
};

/* Extra styling so that form page wouldn't look awful in
  mobile phone landscape mode */
const LandscapeWrapper = styled.div`
  margin: 0;
  ${breakpoint("sm")`
    margin: 0 10%;
  `}
  ${breakpoint("md")`
    margin: 0;
  `}
`;

const ResponsiveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${breakpoint("md")`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
  `};
`;

const StyledP = styled.p`
  white-space: break-spaces;
  ${breakpoint("md")`
    white-space: normal;
  `};
`;

const pageInstructionsText = (formCount) => {
  switch (formCount) {
    case 0:
      return "No organisations that would require re-verification could be found.";
    case 1:
      return "Please verify that the information about the organisation and its provided listings is up to date. "
        + "\n\nYou can review and update the details of each item by clicking on the name of the item.";
    default:
      return "Please verify that the information about all organisations and their provided listings is up to date. "
        + "\n\nYou can review and update the details of each item by clicking on the name of the item. Organisation forms can only be "
        + "submitted one at the time.";
  }
}

const ReverificationChecklist = (props) => {
  /* One thing that is not yet clear is: is user context retrieved synchronously? If not, it could unpredicatably
    cause some issues - errors like 'undefined' does not have 'x' properpty. */
  const [forms, setForms, formsState=[forms, setForms]] = useState([]);
  const [userC, setC, context=[userC, setC]] = useContext(UserContext);
  const [dataIsLoading, setDataIsLoading] = useState(true);

  useEffect(() => {
    dataLoader(userC, setForms, setDataIsLoading);
  }, []);

  if (dataIsLoading) {
    return <AppLoading />;
  }

  return (
    <LandscapeWrapper>
      <h1>Re-verification checklist</h1>
      <StyledP>
        { pageInstructionsText(forms.length) }
      </StyledP>
      <ResponsiveWrapper>
        {forms.map(RevFDatObj => 
          <ReverificationForm 
            key={RevFDatObj.organisation.id}
            formData={RevFDatObj}
            uiUpdate={(oId) => quickUIUpdate(oId, formsState, context)}
          />)}
      </ResponsiveWrapper>
    </LandscapeWrapper>
  );
};

export default ReverificationChecklist;
