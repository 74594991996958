import AppLoading from "../../../AppLoading";
import Button from "../../../components/Button/Button";
import FormError from "../../../components/FormError/FormError";
import FormFieldset from "../../../components/FormFieldset/FormFieldset";
import OrganisationService from "../../../services/OrganisationService/OrganisationService";
import RaisedCard from "../../../components/RaisedCard/RaisedCard";
import React, { useState } from "react";
import RightCheckbox from "../../../components/RightCheckbox/RightCheckbox";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

const CardSizeLimiter = styled.div`
  flex-basis: 49%;
  margin-bottom: 1vw;
`;

const StyledH2 = styled.h2`
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 25px;
`;

const FormCard = ({children}) => (
  <CardSizeLimiter>
    <RaisedCard>
      {children}
    </RaisedCard>
  </CardSizeLimiter>
);

const allItemsChecked = (formValues) => Object.values(formValues).every(v => v);

const updateSessionStorage = (formIdentifier, retrieveFormValues) => {
  const formStateKV = Object.entries(retrieveFormValues());

  const newFormData = formStateKV.reduce((acc, [key, state]) => {
    const [item, _id, id=+_id] = key.split("_");
    return ((item === "organisation") 
      ? (a, p) => ({ ...a, organisation: p })
      : (a, p) => ({ ...a, services:[...(a?.services || []), p] })
      )(acc, { id, checked: state });
  }, {});

  sessionStorage.setItem(
    formIdentifier,
    JSON.stringify(newFormData));
}

const ReverificationForm = ({ formData, uiUpdate: removeSelf }) => {
  const [submitIsLoading, setSubmitIsLoading] = useState(false);
  const [formHasError, setFormHasError] = useState(false);
  const { register, handleSubmit, getValues } = useForm({});

  const formStorageName = formData.organisation.markupName;

  const submitAction = async (formValues) => {
    // TODO: submit could be generalized
    if (allItemsChecked(formValues)) {
      if (submitIsLoading) return;

      setSubmitIsLoading(true);
      const fieldsToUpdate = { inRevalidationProcess: false };
      const updatedOrganisation = await OrganisationService
        .updateOrganisation(formData.organisation.id, fieldsToUpdate);
      setSubmitIsLoading(false);

      if (updatedOrganisation?.inRevalidationProcess === false) {
        toast.success(`Organisation ${updatedOrganisation.name} has been re-verified.`); 
        removeSelf(updatedOrganisation.id);
        sessionStorage.removeItem(formStorageName);
      } else {
        toast.error("Unable to update organisation.");
      }
    } else {
      setFormHasError(true);
    }
  };

  if (submitIsLoading) return (
      <FormCard>
        <AppLoading />
      </FormCard>
    );

  return (
    <FormCard>
      <form
        onSubmit={handleSubmit(() => submitAction(getValues()))}
      >
        <StyledH2>{formData.organisation.name}</StyledH2>
        <FormFieldset label={"Organisation:"}>
          <RightCheckbox
            name={formStorageName}
            label={"Organisation details"}
            register={register}
            initialState={formData.organisation.checked}
            labelLinkUrl={`/organisations/${formData.organisation.id}/edit`}
            onClick={() => updateSessionStorage(formStorageName, getValues)}
          />
        </FormFieldset>
        <FormFieldset label={"Services:"}>
          {formData.services.map((service) => {
            return (
              <div key={service.id}>
                <RightCheckbox
                  name={service.name}
                  label={service.label}
                  register={register}
                  initialState={service.checked}
                  labelLinkUrl={`/services/${service.id}/edit`}
                  onClick={() => updateSessionStorage(formStorageName, getValues)}
                />
              </div>
            );
          })}
        </FormFieldset>
        <Button type="submit" label="Submit ›" margin="0 0 20px 0" />
      </form>
      {formHasError && (
          <FormError
            error={"All items have to be checked to submit the form."}
            marginTop="25px"
            marginBottom="10px"
          />
      )}
    </FormCard>
  );
};

export default ReverificationForm;
