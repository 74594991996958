// The motivation for creating a 2nd checkbox is that more control over stylistic & functional 
// aspects is needed without compormising the layouts of other pages.

import FormError from "../FormError/FormError";
import { Link } from "@reach/router";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
`;

const StyledLabelWraper = styled.label`
  width: 80%;
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 19px;
  margin-left: 10px;
`;

const StyledCheckbox = styled.input`
  width: 20%;
  display: block;
  width: 35px;
  height: 35px;
  margin-right: 5px;
`;

// Turns item into link if the url is given.
const MaybeLinkItem = ({linkUrl, children}) => {
  return linkUrl 
    ? <Link to={linkUrl}>{children}</Link>
    : <>{children}</>;
}

/* Label wrapper needs to be outside Link tag to preserve styling.
The label may or may not be a link (if the url is non-empty) */
const MaybeLinkLabel = ({linkUrl, labelText, labelFor}) => {
  return (
    <StyledLabelWraper htmlFor={labelFor}>
      <MaybeLinkItem linkUrl={linkUrl}>
        {labelText}
      </MaybeLinkItem>
    </StyledLabelWraper>
  );
}

const RightCheckbox = ({
    name,
    label,
    register,
    required,
    error,
    value,
    onClick = () => {},
    dataTestid,
    initialState = false,
    labelLinkUrl = ""
  }) => {
    return (
      <>
        <StyledDiv>
          <MaybeLinkLabel labelText={label} linkUrl={labelLinkUrl} labelFor={name} />
          <StyledCheckbox
            id={name}
            name={name}
            type="checkbox"
            ref={register({ required })}
            aria-invalid={error ? "true" : "false"}
            value={value}
            onClick={onClick}
            data-testid={dataTestid}
            defaultChecked={initialState}
          />
        </StyledDiv>
        {error && error.type === "required" && (
          <FormError error="This is required" />
        )}
      </>
    );
  };
  
  RightCheckbox.propTypes = {
    name: PropTypes.string,
  };
  
  export default RightCheckbox;
  