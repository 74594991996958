import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import FormFieldset from "../../../../components/FormFieldset/FormFieldset";
import FormRadio from "../../../../components/FormRadio/FormRadio";
import FormDropDown from "../../../../components/FormDropDown/FormDropDown";
import Button from "../../../../components/Button/Button";
import styled from "styled-components";
import { red } from "../../../../settings";
import { organisationServiceAreas } from "../../../../settings/organisationServiceArea";
import FormInput from "../../../../components/FormInput/FormInput";
import FormError from "../../../../components/FormError/FormError";
import { Link } from "@reach/router";

const StyledErrorContainer = styled.div`
  padding: 20px;
  border-left: 7px solid ${red[400]};
  border-radius: 2px;
  margin: 5px 0 10px 0; 
`;
const StyledHiddenFieldContainer = styled.div`
  margin-top: 10px;
`;
const StyledRadioOptionsContainer = styled.div`
  display: flex;
`;

const StyledRadioOptionDiv = styled.div`
  margin-top: 10px;
  display: flex;
`;

const StyledRadioOption = styled.div`
  display: inline;
`;

const OrganisationConfirmLocationForm = ({
  onSubmit,
  defaultValues = {},
  showHiddenField,
  setShowHiddenField,
  setShowHiddenFieldSnapshot,
  goBackToPreviousStep,
  setcolOrHackneySelected,
  colOrHackneySelected,
  otherSelected,
  setotherSelected
}) => {
  const { register, handleSubmit, errors, getValues } = useForm({
    defaultValues,
  });
  const getServiceArea = (area) => {
    if(area !== 'Hackney' && area !== 'City Of London'){
      return 'Other';
    }
   
    else return area;
  }
  let intialVal = defaultValues.service_area === undefined ? '' : getServiceArea(defaultValues.service_area);
  const [organisationServiceArea, setOrganisationServiceArea] = useState({area: intialVal});

 
  useEffect(() => {
    if(otherSelected){
      setOrganisationServiceArea({ area: 'Other'});
    }
  }, [setOrganisationServiceArea]);
  
  let organisationServiceAreasArray = Object.values(organisationServiceAreas);

  
  const pageQuestionNames = ["is_hackney_based","is_remote_type","service_area_main","service_area_other"];
 
  const setNotSelectedColOrHackney = (val) =>{
    if(val){  
      setShowHiddenField({
        ...showHiddenField,
        notBasedInWarning: true
      });
      setcolOrHackneySelected(false);
    } else {
      setShowHiddenField({
        ...showHiddenField,
        notBasedInWarning: false
      });
      setcolOrHackneySelected(true);
    }
  }
  const onDropDownChange = (evt) => {
    if (evt.target.value === 'Other') {
       setotherSelected(true);
    } 
    else {
      setotherSelected(false);
     
    }
    setOrganisationServiceArea({ area: evt.target.value});
  }
 
  return (
    <form
      onSubmit={handleSubmit(() => onSubmit(getValues(), pageQuestionNames))}
    >
      <StyledRadioOptionsContainer>
        <FormFieldset label="Does your organisation provide support or activities for Hackney or City residents?">
          <StyledRadioOptionDiv>
            {["Yes", "No"].map((item) => {
              return (
                <StyledRadioOption key={item}>
                  <FormRadio
                    key={item}
                    name="is_hackney_based"
                    label={item}
                    value={item.toLowerCase()}
                    register={register}
                    required
                    onClick={() =>
                      item === "No"
                        ? setNotSelectedColOrHackney(true)
                        : setNotSelectedColOrHackney(false)
                    }
                  />
                </StyledRadioOption>
              );
            })}
          </StyledRadioOptionDiv>
          {errors.is_hackney_based &&
            errors.is_hackney_based.type === "required" && (
              <FormError
                error={"Please complete this question"}
                marginTop="10px"
                marginBottom="10px"
              />
            )}
        </FormFieldset>
      </StyledRadioOptionsContainer>
      {showHiddenField.notBasedInWarning && (
        <StyledErrorContainer
          style={{ backgroundColor: "rgba(190, 58, 52, 0.05)" }}
        >
          <p>
            Your organisation needs to offer services to Hackney or City of
            London residents to be listed in Find support services.
          </p>

          <p>
            Contact <a href="mailto:fss@hackney.gov.uk">fss@hackney.gov.uk</a>{" "}
            if you wish to discuss further.
          </p>
        </StyledErrorContainer>
      ) }
      {colOrHackneySelected && 
        <StyledRadioOptionsContainer>
          <FormFieldset label="Where does your organisation provide service?">
            {             
              <FormDropDown
                label={"Select Service Area"}
                name={"service_area_main"}
                includeBlankValue={false}
                register={register}
                required
                onChange={(evt) => onDropDownChange(evt)}
                options={organisationServiceAreasArray}
                values={organisationServiceAreasArray.map((area) =>
                  area
                )}
                value={organisationServiceArea.area}
                error={errors.service_area_main}
              />
            }
            
          </FormFieldset>
        </StyledRadioOptionsContainer>
      }
      {otherSelected &&
              <StyledHiddenFieldContainer>
                <FormInput
                  name="service_area_other"
                  type="text"
                  label={"Please specify your organisation's service area"}
                  register={register}
                  error={errors.service_area_other}
                  required
                />
              </StyledHiddenFieldContainer>
            }
      <StyledRadioOptionsContainer>
        <FormFieldset label="Does your organisation operate remotely?">
            <StyledRadioOptionDiv>
              {["Yes", "No"].map((item) => {
                return (
                  <StyledRadioOption key={item}>
                    <FormRadio
                      key={item}
                      name="is_remote_type"
                      label={item}
                      value={item.toLowerCase()}
                      register={register}
                      required
                      onClick={() =>
                        item === "No"
                          ? setShowHiddenField({
                              ...showHiddenField,
                              notRemoteTypeWarning: true,
                            })
                          : setShowHiddenField({
                              ...showHiddenField,
                              notRemoteTypeWarning: false,
                            })
                      }
                    />
                  </StyledRadioOption>
                );
              })}
            </StyledRadioOptionDiv>
            {errors.is_remote_type &&
              errors.is_remote_type.type === "required" && (
                <FormError
                  error={"Please complete this question"}
                  marginTop="10px"
                  marginBottom="10px"
                />
              )}
          </FormFieldset>
      </StyledRadioOptionsContainer>
      { !showHiddenField.notBasedInWarning && (
        <Button type="submit" label="Continue ›" margin="0 0 20px 0" />
        )
      }
      <Link to="/" onClick={(e) => goBackToPreviousStep(e)}>
        Go back to previous step
      </Link>
    </form>
  );
};

export default OrganisationConfirmLocationForm;
